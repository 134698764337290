<template>
	<div class="container">
		<div class="resultBox">
			<div class="result">
				<div class="resultImage"></div>
				<div class="label">
					<div class="label-l"></div>
					<div class="label-r">
						<div class="currentLabel">{{data.level}}</div>
						<div class="prevTime">上次测评时间:{{data.lastTestDate}}</div>
					</div>
				</div>
				<div class="label1">适合购买产品的风险等级为</div>
				<div class="label2">{{data.fitProdForClient}}</div>
				<div class="notes">
					<div></div>
					<text>测评须知</text>
					<div></div>
				</div>
				<div class="detail">
					<div class="detailItem">
						· 测评结果分为5种，详阅 <text>《适当性匹配须知》</text>。该适当性匹配意见不表明我公司对产品的风险和收益作出任何实质性判断或保证。
					</div>
					<div class="detailItem">
						· 风险测评有效期为1年。
					</div>
					<div class="detailItem">
						· 若您的信息发生变化，请及时重测，购买时请关注风险承受能力与产品的匹配情况。
					</div>
				</div>	
			</div>
		</div>
		<div class="optBox">
			<navigator url="./index" open-type="redirect" class="reset">重新测试</navigator>
			<navigator url="/pages/ofundAll/ofundAll" class="select">选购基金</navigator>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				data:{},
			}
		},
		onLoad(opt){
			this.data = opt;
		},
	}
</script>

<style scoped>	
	.container{
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-color: #F8F7F7;
	}
	.resultBox{
		flex: 1;
		padding: 43px 24px 0;
	}
	.result{
		padding: 127px 0 0 0;
		width: 100%;
		height: 949px;
		background-color: #fff;
		background-image: url(../../../static/img/my/riskBg.png);
		background-size: 100% 100%;
		position: relative;
		box-shadow: 0px 9.34975px 18.6995px rgba(41, 41, 42, 0.07);
		border-radius: 12px;
	}
	.resultImage{
		width: 218px;
		height: 173px;
		position: absolute;
		right: 0;
		top: 20px;
		background-image: url(../../../static/img/my/riskRestlt.png);
		background-size: 100% 100%;
	}
	.label{
		display: flex;
		align-items: center;
		margin: 0 0 0 55px;
	}
	.label-l{
		margin-right: 37px;
		width: 11px;
		height: 122px;
		background: linear-gradient(180deg, #FCEEDD -6.97%, #EABF96 100.26%);
	}
	.currentLabel{
		color: #6C461C;
		font-size: 42px;
		font-weight: bold;
		margin-bottom: 28px;
	}
	.prevTime{
		font-size: 28px;
		color: #8691A8;
	}
	.label1{
		margin: 71px 0 28px 55px;
		color: #25293D;
		font-size: 30px;
	}
	.label2{
		word-break: break-all;
		padding-right: 60px;
		margin-left: 55px;
		color: #E45247;
		font-size: 36px;
		line-height: 52px;
		font-weight: bold;
	}
	.notes{
		padding: 0 15.5px;
		color: #B88141;
		font-size: 30px;
		margin: 50px 0 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.notes>div{
		width: 100px;
		height: 2px;
		background-color: #D8B691;
	}
	.detail{
		color: #766755;
		font-size: 24px;
	}
	.detailItem{
		padding: 0 43.6px;
		margin-bottom: 30px;
	}
	.detailItem>text{
		color: #E45247;
	}
	.optBox{
		text-align: center;
		line-height: 100px;
		color: #B88141;
		font-size: 36px;
		padding: 31px 24px;
		display: flex;
		justify-content: space-between;
	}
	.reset{
		width: 242px;
		height: 100px;
		border: solid 2px #B88141;
		border-radius: 50px;
	}
	.select{
		width: 421px;
		height: 100px;
		background: linear-gradient(90deg, #F7D9B7 0%, #EABF96 95.83%);
		border-radius: 50px;
	}
</style>
